import React, { useEffect, useState } from "react";
import "./BookConsultation.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Button, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GetCities, BookConsultation as bcon } from "../../apis/MiscAPI";
import Loader from "../Spinner/Loader";
import AddUser from "../../images/add-user.png";
import JoinCommunityIcon from "../../images/join-group.png";
import BookIcon from "../../images/envelope-email.png";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";

export default function BookConsultation() {
  const { location } = useSelector((state) => state.auth);
  const [isActive, setActive] = useState("false");
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const defaultConsultation = {
    userName: "",
    location: "",
    phoneNo: "",
    email: "",
    city: "",
  };
  const [consultation, setConsultation] = useState(defaultConsultation);
  const [showLoader, setShowLoader] = useState(false);
  const [refNo, setRefNo] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [hideSubmit, setHideSubmit] = useState({
    submit: false,
    submitted: true,
  });

  const validationRegex = {
    userName: /^[a-zA-Z. ]{3,30}$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?$/,
  };

  const updateConsultationData = (key, value) => {
    setConsultation((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [phoneValidated, setPhoneValidated] = useState(false);
  const [validated, setValidated] = useState(false);

  function formValid() {
    //console.log(consultation);
    for (const key in consultation) {
      switch (key) {
        case "userName":
          if (!validationRegex.userName.test(consultation[key])) return false;
          break;
        case "phoneNo":
          if (!isValidPhoneNumber(consultation[key], countryCode)) {
            setPhoneValidated(false);
            return false;
          } else setPhoneValidated(true);
          break;
        case "email":
          if (!validationRegex.email.test(consultation[key])) return false;
          break;
        default:
          if (consultation[key] === "" || consultation[key] === undefined)
            return false;
          break;
      }
    }
    return true;
  }

  useEffect(() => {
    if (location != null) getCountry();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (country != "" && country != null && country != undefined) {
      getCities();
    }
  }, [country]);

  const getCountry = () => {
    setCountryCode(location.countryCode);
    updateConsultationData("location", location.city + ", " + location.country);
    setCountry(location.country);
  };

  const getCities = async () => {
    var result = await GetCities(countryCode);
    setCities(result);
  };

  const ToggleClass = () => {
    setActive(!isActive);
  };

  const handleConsultation = async (e) => {
    e.preventDefault();
    setIsInitialLoad(false);
    const form = e.currentTarget;
    var valid = formValid();
    if (form.checkValidity() === false || !valid) {
      e.stopPropagation();
    } else {
      e.preventDefault();
      const data = {
        id: 0,
        userName: consultation.userName,
        location: consultation.location,
        city: consultation.city,
        phoneNo: consultation.phoneNo,
        email: consultation.email,
        createdDate: "0001-01-01T00:00:00",
        updatedDate: null,
      };
      setShowLoader(true);
      var result = await bcon(data);
      setRefNo(result);
      setShowLoader(false);
      setHideSubmit({ submit: true, submitted: false });
      setConsultation(defaultConsultation);
      updateConsultationData(
        "location",
        location.city + ", " + location.country
      );
      setIsInitialLoad(true);
      changeToDefaultButtonText();
    }
    setValidated(false);
  };

  const handleCityChange = (selectedOptions) => {
    if (selectedOptions.length > 0) {
      const selectedCityName = selectedOptions[0].name;
      setSelectedCity(selectedOptions);
      updateConsultationData("city", selectedCityName);
    } else {
      setSelectedCity([]); // Clear selection if no option is selected
      updateConsultationData("city", ""); // Clear city in contactUsData
    }
  };

  const handlePhoneValidation = () => {
    var vp = isValidPhoneNumber(consultation.phoneNo, countryCode);
    if (!vp) setPhoneValidated(false);
    else setPhoneValidated(true);
  };

  const changeToDefaultButtonText = () => {
    setTimeout(() => {
      setSelectedCity([]);
      setHideSubmit({ submit: false, submitted: true });
    }, 2000);
  };

  return (
    <>
      {/* <div
        className={
          isActive ? "consultation-bnr hide-box" : "consultation-bnr show-box"
        }
      >
        <button className="consultation-btn" onClick={ToggleClass}>
          <span className="link-text">Book a Consultation</span>
          <span className="icon">
            <img src={BookIcon} alt="Tow To Tango" />
          </span>
        </button>
        <div className="consultation-bnr-form">
          <div className="consultation-head">
            <p>
              Ready for something special?
              <br />
              Book a free consultation with our expert matchmaker today!
            </p>
          </div>
          <Form
            noValidate
            validated={validated}
            className="book-consultation"
            autoComplete="off"
            onSubmit={handleConsultation}
          >
            <Form.Group controlId="Name" className="book-consultation-input">
              <div className="book-consultation-input-inner">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={consultation.userName}
                  onChange={(e) =>
                    updateConsultationData("userName", e.target.value)
                  }
                  isInvalid={
                    !isInitialLoad &&
                    !validationRegex.userName.test(consultation.userName)
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid name (length must be 3-30 characters,
                  alphabetic characters only).
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group
              controlId="Location"
              className="book-consultation-input"
            >
              <div className="book-consultation-input-inner">
                <Form.Label>Location:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={consultation.location}
                  onChange={(e) =>
                    updateConsultationData("location", e.target.value)
                  }
                  isInvalid={
                    !isInitialLoad && consultation.location === ""
                      ? true
                      : false
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide location
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group controlId="City" className="book-consultation-input">
              <div className="book-consultation-input-inner">
                <Form.Label>City:</Form.Label>
                <Typeahead
                  id="consultation_city"
                  labelKey="name"
                  options={cities}
                  placeholder="city living in"
                  selected={selectedCity}
                  onChange={handleCityChange} 
                  isInvalid={
                    !isInitialLoad &&
                    (!selectedCity ||
                      selectedCity.length === 0 ||
                      consultation.city === "")
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please provide name of the city you live in
                </Form.Control.Feedback> 
              </div> 
            </Form.Group>
            <Form.Group controlId="PhoneNo" className="book-consultation-input">
              <div className="book-consultation-input-inner">
                <Form.Label>Phone No:</Form.Label>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry={countryCode}
                  placeholder="Enter phone number"
                  value={consultation.phoneNo}
                  onChange={(e) => updateConsultationData("phoneNo", e)}
                  onBlur={handlePhoneValidation}
                  required
                />
              </div>
              {!isInitialLoad && !phoneValidated && (
                <Form.Text className="text-danger">
                  Please provide valid phone number
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="Email" className="book-consultation-input">
              <div className="book-consultation-input-inner">
                <Form.Label>Email:</Form.Label>
                <Form.Control
                  type="email"
                  placeholder=""
                  value={consultation.email}
                  onChange={(e) =>
                    updateConsultationData("email", e.target.value)
                  }
                  isInvalid={
                    !isInitialLoad &&
                    !validationRegex.email.test(consultation.email)
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Button
              className="consultation-submit"
              type="submit"
              hidden={hideSubmit.submit}
            >
              Submit
              <Loader visible={showLoader} />
            </Button>
            <Button
              className="consultation-submit"
              disabled
              hidden={hideSubmit.submitted}
            >
              Submitted
            </Button>
          </Form>
        </div>
      </div> */}
      <div className="signup-bnr">
        <Link to="/signup">
          <span className="link-text">Sign Up</span>
          <span className="link-icon">
            <img src={AddUser} alt="Tow To Tango" />
          </span>
        </Link>
      </div>
      <div className="signup-bnr join-community-btn">
        <Link to="/JoinOurCommunity">
          <span className="link-text">Join Our Community</span> 
          <span className="link-icon">
            <img src={JoinCommunityIcon} alt="Tow To Tango" />
          </span>
        </Link>
      </div>
      <Modal
        className="upgrade-membership-modal"
        show={showMessage}
        onHide={() => setShowMessage(false)}
        size="md"
        centered
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>
          Consultation booked. Your reference no. is {refNo}
        </Modal.Body>
      </Modal>
    </>
  );
}
