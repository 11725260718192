import React from "react";
import "./WhyUs.css";
import { Col, Container, Row } from "react-bootstrap";
import BookConsultation from "../../Components/BookConsultation/BookConsultation";
import WhyUsBnr from "../../images/why-bnr.png";
import Why4Col1 from "../../images/why-4col1.jpg";
import Why4Col2 from "../../images/why-4col2.jpg";
import Why4Col3 from "../../images/why-4col3.jpg";
import Why4Col4 from "../../images/why-4col4.jpg";
import Why3Col1 from "../../images/why-3col1.jpg";
import Why3Col2 from "../../images/why-3col2.jpg";
import Why3Col3 from "../../images/why-3col3.jpg";
import WhyusBtm from "../../images/why-btm.jpg";
import { Helmet } from "react-helmet-async";

export default function WhyUs() {
  return (
    <>
      <Helmet>
        <title>Experience Real Connections, Offline and In-Person</title>
        <meta name="description" content="Two To Tango combines offline meetups, personalised matchmaking, and expert guidance for singles seeking authentic connections." />
      </Helmet>
      <section className="why-bnr">
        <Container fluid>
          <Row>
            <Col lg={12} md={12} className="why-bnr-col">
              <img src={WhyUsBnr} alt="Tow To Tango" />
            </Col>
          </Row>
        </Container>
        <BookConsultation />
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <div className="whyus-head">
                <div className="whyus-head-inner">
                  <h2
                    className="text-styled"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    <span>Communities thrive on connections</span>
                  </h2>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="why-4col-row">
            <Col lg={3} md={6}>
              <div className="why-4col">
                <div className="why-4col-img">
                  <img
                    src={Why4Col1}
                    alt="Tow To Tango"
                    data-aos="fade-in-up"
                    data-aos-duration="500"
                  />
                </div>
                <div className="why-4col-text">
                  <div
                    className="why-4col-text-info1"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <p>
                      <span>82%</span> of people believe{" "}
                      <strong>human connection</strong> — romantic or not — is
                      essential for mental and{" "}
                      <strong>emotional well-being.</strong>
                    </p>
                  </div>
                  {/* <div className='why-4col-text-info2' data-aos="fade-up" data-aos-duration="1000"> 
                                    <p>Authenticity is our forte.</p>
                                </div> */}
                </div>
                <span className="source-why-col">Source: Ipsos</span>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="why-4col">
                <div className="why-4col-img">
                  <img
                    src={Why4Col2}
                    alt="Tow To Tango"
                    data-aos="fade-in-up"
                    data-aos-duration="500"
                  />
                </div>
                <div className="why-4col-text">
                  <div
                    className="why-4col-text-info1"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <p>
                      <span>63%</span> <strong>of singles</strong> prefer to meet
                      potential <strong>partners in person</strong>, rather than
                      through dating apps.
                    </p>
                  </div>
                  {/* <div className='why-4col-text-info2' data-aos="fade-up" data-aos-duration="1000">
                                    <p>Our holistic process significantly boosts your chances of finding a compatible match.</p>
                                </div> */}
                </div>
                <span className="source-why-col">
                  Source: Pew Research Center
                </span>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="why-4col">
                <div className="why-4col-img">
                  <img
                    src={Why4Col3}
                    alt="Tow To Tango"
                    data-aos="fade-in-up"
                    data-aos-duration="500"
                  />
                </div>
                <div className="why-4col-text">
                  <div
                    className="why-4col-text-info1"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <p> 
                      {/* <strong>Single households</strong> are predicted to see a{" "}
                      <span>128%</span> <strong>global growth by 2030</strong>,
                      driven by newfound financial and lifestyle freedoms. */}
                      Prolonged social isolation and loneliness are equivalent to smoking <strong>15 cigarettes</strong> a day.
                    </p>
                  </div>
                  {/* <div className='why-4col-text-info2' data-aos="fade-up" data-aos-duration="1000">
                                    <p>Your time deserves intentional, curated matches.</p>
                                </div> */}
                </div>
                <span className="source-why-col">Source: National Institute on Aging</span>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="why-4col">
                <div className="why-4col-img">
                  <img
                    src={Why4Col4}
                    alt="Tow To Tango"
                    data-aos="fade-in-up"
                    data-aos-duration="500"
                  />
                </div>
                <div className="why-4col-text">
                  <div
                    className="why-4col-text-info1"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <p>
                      <span>67%</span> of singles agree that engaging in{" "}
                      <strong>offline group activities</strong> — like hobby
                      classes and community meetups — create better{" "}
                      <strong>connections</strong> than dating apps.
                    </p>
                  </div>
                  {/* <div className='why-4col-text-info2' data-aos="fade-up" data-aos-duration="1000">
                                    <p>With clarity and confidence, we cut through the clutter for you.</p>
                                </div> */}
                </div>
                <span className="source-why-col">Source: Eventbrite</span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="why-3col-section">
        <Container>
          <Row>
            <Col>
              <div className="whyus-head whyus-head2">
                <div
                  className="whyus-head-inner"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <h2>
                    <span>Unforgettable adventures</span> at the touch of your
                    fingertips
                  </h2>
                  {/* <small>Find the match you seek with three easy steps:</small> */}
                  {/* <small>Begin your 3-step matchmaking journey.</small> */}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <div className="why-3col">
                <div className="why-3col-img">
                  <img
                    src={Why3Col1}
                    alt="Tow To Tango"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  />
                </div>
                <div className="why-3col-text">
                  <div className="why-3col-text-info1">
                    <h6 data-aos="fade-up" data-aos-duration="500">
                      Join the Community:
                    </h6>
                    <p data-aos="fade-up" data-aos-duration="1000">
                    Complete a simple form to enjoy complimentary membership, provided you meet our eligibility criteria during our initial vetting process.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="why-3col">
                <div className="why-3col-img">
                  <img
                    src={Why3Col2}
                    alt="Tow To Tango"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  />
                </div>
                <div className="why-3col-text">
                  <div className="why-3col-text-info1">
                    <h6 data-aos="fade-up" data-aos-duration="500">
                      Engage and Connect:
                    </h6>
                    <p data-aos="fade-up" data-aos-duration="1000">
                    Participate in our exclusive events, be it curated interest-based meetups, singles mixers, or candid webinars. Immerse yourself in deep dives on personal finance, mental health, entrepreneurship, relationships, travel, and much more.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="why-3col">
                <div className="why-3col-img">
                  <img
                    src={Why3Col3}
                    alt="Tow To Tango"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  />
                </div>
                <div className="why-3col-text">
                  <div className="why-3col-text-info1">
                    <h6 data-aos="fade-up" data-aos-duration="500">
                      Personalised Matchmaking:
                    </h6>
                    <p data-aos="fade-up" data-aos-duration="1000">
                    With over a decade of global matchmaking expertise, our bespoke service, available for a fee, is designed to cater to your unique needs. Experience the value of having a dedicated expert in your corner, working with you as a team to find meaningful connections tailored to your unique preferences.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <div className="whyus-btm">
                <img
                  src={WhyusBtm}
                  alt="Tow To Tango"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
