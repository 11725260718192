import React, { useEffect, useState } from "react";
import "./JoinCommunityQuestionnaire.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import DocIcon from "../../images/doc-icon.png";
import "react-phone-number-input/style.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import {
  EmailExists,
  GetCommunityQnA,
  SaveJoinOurCommunity,
  SignupUser,
  VerifyUser,
} from "../../apis/UserAPI";
import Loader from "../../Components/Spinner/Loader";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  GetCities,
  GetCountryList,
  GetCurrencyList,
  GetSalaryList,
} from "../../apis/MiscAPI";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";
import { FiletypePdf } from "react-bootstrap-icons";

export default function JoinCommunityQuestionnaire() {
  const { location } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [phoneValidated, setPhoneValidated] = useState(true);
  const [dobValidated, setDobValidated] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [currencyList, setCurrencyList] = useState([]);
  const [salaryList, setSalaryList] = useState([]);
  const [allSalaries, setAllSalaries] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const validationRegex = {
    firstName: /^[a-zA-Z' -]{2,30}$/,
    lastName: /^[a-zA-Z' -]{2,}$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?$/,
    age: /^(1[8-9]|[2-6][0-9]|70)$/,
  };

  const [signUpCommunityData, setSignUpCommunityData] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNo: "",
    dob: "",
    age: "",
    location: "",
    country: "",
    city: "",
    gender: "",
    qna: [],
    captcha: "",
    consent: "",
    tnc: "",
    membershipId: 0,
    profilePic: "",
    proofDoc: "",
  });

  const [maxDate, setMaxDate] = useState("");
  const [minDate, setMinDate] = useState("");

  const [validated, setValidated] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [invalidEmailMsg, setInvalidEmailMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [alreadySigned, setAlreadySigned] = useState(false);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [formSubmitError, setFormSubmitError] = useState("");
  const firstPageQ = [
    1, 2, 73, 5, 6, 35, 36, 37, 40, 9, 11, 12, 13, 14, 70, 71, 72,
  ];
  const secondPageQ = [
    20, 21, 22, 23, 24, 7, 8, 25, 26, 27, 30, 33, 65, 66, 34, 61,63
  ];

  const updateSignUpCommunityData = (key, value) => {
    if (key === "dob") {
      value = ConvertDate(value);
      calculateAge(value);
      setDobValidated(true);
    } else if (key === "consent") {
      if (signUpCommunityData.consent === "consented") value = "";
    } else if (key === "tnc") {
      if (signUpCommunityData.tnc === "agreed") value = "";
    }
    setSignUpCommunityData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const ConvertDate = (value) => {
    return new Date(
      Date.UTC(
        value.getFullYear(),
        value.getMonth(),
        value.getDate(),
        value.getHours(),
        value.getMinutes(),
        value.getSeconds()
      )
    );
  };

  const updateQnA = (qn, ans, render) => {
    var updatedUserQnA = signUpCommunityData.qna;
    console.log(updatedUserQnA);
    const existingQIndex = updatedUserQnA.findIndex((q) => q.id === qn.id);

    if (existingQIndex !== -1) {
      // Update existing question
      const isInitialRender = render === "initial";
      qn.answer = isInitialRender ? qn.answer : ans;
      updatedUserQnA[existingQIndex] = qn;
    } else {
      // Add new question
      updatedUserQnA.push(qn);
    }
    updateSignUpCommunityData("qna", updatedUserQnA);

    if (qn.id === 72 && ans != null && ans.indexOf("Romantic") !== -1) {
      setRomanticOpt(true);
    } else if (
      qn.id === 72 &&
      (ans === null || ans.indexOf("Romantic") === -1)
    ) {
      setRomanticOpt(false);
      setTimeout(() => {
        loadCaptchaEnginge(6, "#CDAC45", "white");
      }, 2000);
    }
  };

  const getCurrencies = async () => {
    var data = await GetCurrencyList();
    setCurrencyList(data);
  };

  const getSalaries = async () => {
    var data = await GetSalaryList();
    setAllSalaries(data);
  };

  function formValid() {
    //console.log(signUpCommunityData);
    for (const key in signUpCommunityData) {
      // Skip validation for membershipId
      if (key !== "membershipId") {
        // Handle specific validations based on key
        switch (key) {
          case "qna":
            // Validate array of Q&A pairs
            let qna = signUpCommunityData[key];
            if (!romanticOpt)
              qna = qna.filter((x) => firstPageQ.includes(x.id));
            console.log(qna);
            for (const qa of qna) {
              if (!qa.answer) {
                return false; // Return false if any answer is missing
              }
            }
            break;
          case "emailId":
            // Validate email using regex
            if (
              !validationRegex.email.test(signUpCommunityData[key]) ||
              isEmailInvalid
            ) {
              return false; // Return false if email is invalid
            }
            break;
          case "phoneNo":
            // Validate phone number
            if (
              signUpCommunityData[key] === "" ||
              isValidPhoneNumber(signUpCommunityData[key], countryCode) !== true
            ) {
              handlePhoneValidation();
              return false; // Return false if phone number is invalid
            }
            break;
          case "dob":
            // Validate date of birth
            if (!signUpCommunityData[key]) {
              setDobValidated(false); // Set date of birth validation status
              return false; // Return false if date of birth is missing
            } else {
              setDobValidated(true); // Set date of birth validation status
            }
            break;
          case "firstName":
            // Validate first name using regex
            if (!validationRegex.firstName.test(signUpCommunityData[key])) {
              return false; // Return false if first name is invalid
            }
            break;
          case "lastName":
            // Validate last name using regex
            if (!validationRegex.lastName.test(signUpCommunityData[key])) {
              return false; // Return false if last name is invalid
            }
            break;
          case "age":
            // Validate age using regex
            if (!validationRegex.age.test(signUpCommunityData[key])) {
              return false; // Return false if age is invalid
            }
            break;
          case "profilePic":
          case "proofDoc":
            if (romanticOpt && signUpCommunityData[key] === "") return false;
            break;
          default:
            // Validate for any other required fields
            if (!signUpCommunityData[key] || signUpCommunityData[key] === "") {
              return false; // Return false if any other required field is missing
            }
        }
      }
    }

    // If all validations pass, return true
    return true;
  }

  const validateEmail = async () => {
    let emailid = signUpCommunityData.emailId;
    if (validationRegex.email.test(emailid)) {
      let uid = await EmailExists(emailid);
      if (uid !== 0) {
        setIsEmailInvalid(true);
        setInvalidEmailMsg("Email already exists!");
      } else {
        setIsEmailInvalid(false);
        setInvalidEmailMsg("");
      }
    } else {
      setIsEmailInvalid(true);
      setInvalidEmailMsg("Invalid email format");
    }
  };

  useEffect(() => {
    const userId = Cookies.get("userid");
    if (userId === undefined || userId === "" || userId === null) {
      loadSignup();
    } else verifyUserId(encodeURIComponent(userId));
  }, [countryCode]);

  useEffect(() => {
    if (alreadySigned) {
      const userId = Cookies.get("userid");
      navigate(`/payment?uid=${encodeURIComponent(userId)}&type=new`);
    } else {
      loadSignup();
    }
  }, [alreadySigned]);

  const loadSignup = () => {
    calculateMaxDate();
    loadCaptchaEnginge(6, "#CDAC45", "white");
  };

  useEffect(() => {
    if (location != null) getCountry();
  }, [location]);

  const getCountry = async () => {
    setCountry(location.country);
    setCountryCode(location.countryCode);
    updateSignUpCommunityData("location", location.country);
    updateSignUpCommunityData("country", location.countryCode);
  };

  useEffect(() => {
    if (country != "" && country != null && country != undefined) {
      getCities();
      getQn();
      getCurrencies();
      getSalaries();
      getCountries();
    }
  }, [country]);

  const getCities = async () => {
    var result = await GetCities(countryCode);
    setCities(result);
  };

  const getCountries = async () => {
    var result = await GetCountryList();
    setCountryList(result);
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e);
    var salList = allSalaries.filter((x) => x.salary_type === e);
    setSalaryList(salList);
  };

  const verifyUserId = async (uid) => {
    var response = await VerifyUser(uid);
    setAlreadySigned(response);
  };

  const getQn = async () => {
    var result = await GetCommunityQnA();
    result.forEach((element) => {
      updateQnA(element, null, "initial");
    });
  };

  const calculateMaxDate = () => {
    const today = new Date();
    var mxDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    var mnDate = new Date(
      today.getFullYear() - 70,
      today.getMonth(),
      today.getDate()
    );
    setMaxDate(mxDate);
    setMinDate(mnDate);
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();

    // Adjust for birth month and day
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    updateSignUpCommunityData("age", age);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsInitialLoad(false);

    const form = event.currentTarget;
    let userCaptcha = document.getElementById("captcha").value;
    if (validateCaptcha(userCaptcha) === true && formValid()) {
      setFormSubmitError("");
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        event.preventDefault();
        var qna = [];
        signUpCommunityData.qna.forEach((item) => {
          qna.push({ QuestionId: item.id, Answer: item.answer });
        });
        const userData = {
          firstName: signUpCommunityData.firstName,
          lastName: signUpCommunityData.lastName,
          dob: signUpCommunityData.dob,
          age: signUpCommunityData.age + "",
          gender: signUpCommunityData.gender,
          userEmail: signUpCommunityData.emailId,
          phoneNo: signUpCommunityData.phoneNo,
          location: signUpCommunityData.location,
          country: signUpCommunityData.country,
          city: signUpCommunityData.city,
          membershipId: 0,
          qnA: qna,
          profilePic: signUpCommunityData.profilePic,
          proofDoc: signUpCommunityData.proofDoc,
        };

        setShowLoader(true);
        //console.log(userData);
        await SaveJoinOurCommunity(userData);
        navigate('/ThankYou?msg="joc"');
        setShowLoader(false);
      }
    } else {
      setFormSubmitError("Please fill all the fields");
      updateSignUpCommunityData("captcha", "");
    }
    setValidated(false);
  };

  const handleCityChange = (selectedOptions) => {
    if (selectedOptions.length > 0) {
      const selectedCityName = selectedOptions[0].name;
      setSelectedCity(selectedOptions);
      updateSignUpCommunityData("city", selectedCityName);
    } else {
      setSelectedCity([]); // Clear selection if no option is selected
      updateSignUpCommunityData("city", ""); // Clear city in contactUsData
    }
  };

  const handlePhoneValidation = () => {
    var vp = isValidPhoneNumber(signUpCommunityData.phoneNo, countryCode);
    if (!vp) setPhoneValidated(false);
    else setPhoneValidated(true);
  };

  const [commonQues, setCommonQues] = useState(true);
  const [showRomantic, setShowRomantic] = useState(false);
  const onClickRomantic = () => {
    setCommonQues(false);
    setShowRomantic(true);
    setIsInitialLoad(true);
    setFormSubmitError("");
    window.scroll(0, 0); 
    document.body.scrollTop = 0;
  };

  const [showRomanticUpload, setShowRomanticUpload] = useState(false);
  const onClickRomanticUpload = () => {
    setShowRomantic(false);
    setShowRomanticUpload(true);
    window.scroll(0, 0); 
    document.body.scrollTop = 0;
  };

  const [romanticOpt, setRomanticOpt] = useState(false);

  const handleCountryChange = (item, selectedOptions) => {
    // console.log(selectedOptions);
    if (selectedOptions.length > 0) {
      var naOpt = selectedOptions.filter((x) => x.country_id === 0);
      if (naOpt != null && naOpt.length > 0) selectedOptions = naOpt;
      setSelectedCountries(selectedOptions);
      var ids = selectedOptions.map((item) => item.country_id);
      var joined_ids = ids.join(",");
      console.log(joined_ids);
      updateQnA(item, `Yes^${joined_ids}`, null);
    } else {
      setSelectedCountries([]); // Clear selection if no option is selected
      updateQnA(item, "", null); // Clear country in answer
    }
  };

  const handleCheckboxChange = (event, item) => {
    const value = event.target.value;
    var schk = item.answer == null ? [] : item.answer.split("~");
    if (schk.some((item) => item.trim().includes(value))) {
      schk = schk.filter((option) => option !== value);
      if (value.indexOf("Other") !== -1)
        schk = schk.filter((option) => !option.startsWith("Other"));
    } else {
      schk = [...schk, value];
    }
    schk = schk.filter((x) => x !== "");
    if (item.id === 70 && schk.length > 3) return false;
    else updateQnA(item, schk.join("~"));
  };

  const start = 18;
  const end = 70;
  const ageArray = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );

  const handleAge = (item, v, type) => {
    // console.log(item, v, type);
    var age =
      item.answer == null || item.answer == "null" || item.answer == ""
        ? [0, 0]
        : item.answer.split(",");
    if (type === "from") age[0] = v;
    if (type === "to") age[1] = v;

    if (
      age[0] <= age[1] ||
      item.answer == null ||
      item.answer == "null" ||
      item.answer == ""
    ) {
      var ans = `${age[0]},${age[1]}`;
      updateQnA(item, ans);
    }
  };

  const handlePrevClic = (prev) => {
    if (prev === 1) {
      setCommonQues(true);
      setShowRomantic(false);
      window.scrollTo(0, 0);
    }
    if (prev === 2) {
      setCommonQues(false);
      setShowRomantic(true);
      setShowRomanticUpload(false);
      window.scrollTo(0, 0);
    }
  };

  const handleProofUpload = () => {
    document.getElementById("prooffile").click();
  };

  const handlePicUpload = () => {
    document.getElementById("picfile").click();
  };

  const maxFileSize = 5 * 1024 * 1024;
  const [picError, setPicError] = useState("");
  const [docError, setDocError] = useState("");
  const [docTypePDF, setDocTypePDF] = useState(false);
  const handleUpload = (e, type) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > maxFileSize) {
        if (type === "pic") setPicError("File size must be less than 5MB.");
        if (type === "doc") setDocError("File size must be less than 5MB.");
        return;
      }

      const picallowedTypes = ["image/jpeg", "image/png"];
      const docallowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (
        (type === "pic" && !picallowedTypes.includes(selectedFile.type)) ||
        (type === "doc" && !docallowedTypes.includes(selectedFile.type))
      ) {
        if (type === "doc")
          setPicError("Invalid file type. Only JPG, PNG, and PDF are allowed.");
        else if (type === "pic")
          setDocError("Invalid file type. Only JPG, PNG are allowed.");
        return;
      }

      if (type === "doc" && selectedFile.type === "application/pdf")
        setDocTypePDF(true);
      else setDocTypePDF(false);

      setPicError("");
      setDocError("");

      const reader = new FileReader();
      reader.onloadend = () => {
        // const base64 = reader.result.split(",")[1];
        if (type === "pic")
          updateSignUpCommunityData("profilePic", reader.result);
        // Set the Base64 string
        else if (type === "doc")
          updateSignUpCommunityData("proofDoc", reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const feet = ["4 feet", "5 feet", "6 feet", "7 feet"];
  const inches = [
    "0 inch",
    "1 inch",
    "2 inches",
    "3 inches",
    "4 inches",
    "5 inches",
    "6 inches",
    "7 inches",
    "8 inches",
    "9 inches",
    "10 inches",
    "11 inches",
  ];

  const handleHeight = (item, v) => {
    var hght = item.answer == null ? [0, 0] : item.answer.split(".");
    if (v.indexOf("feet") !== -1) hght[0] = v.substring(0, v.indexOf("f") - 1);
    if (v.indexOf("inch") !== -1) hght[1] = v.substring(0, v.indexOf("i") - 1);

    var ans = `${hght[0]}.${hght[1] === undefined ? "0" : hght[1]}`;
    updateQnA(item, ans, null);
  };

  const handleOtherText = (event, item) => {
    const value = `Other^${event.target.value}`;
    var schk =
      item.answer == null || item.answer == "" ? [] : item.answer.split("~");
    schk = schk.filter((option) => option.indexOf("Other") === -1);
    schk = [...schk, value];
    updateQnA(item, schk.join("~"));
  };

  return (
    <>
      <section className="signup-page join-community-page">
        <Container>
          <Row>
            <Col>
              <div className="signup-form-head">
                <h2
                  className="healine mb-5"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                >
                  <span>Join Our Community</span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="join-community-form-bg">
            <Col lg={4} className="signup-form-col-left">
              <div className="signup-form">
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <Row
                    className="mb-3"
                    style={{
                      display: commonQues ? "" : "none",
                    }}
                  >
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="FName"
                      label="First Name"
                    >
                      <Form.Control
                        required
                        type="text"
                        placeholder="First Name"
                        value={signUpCommunityData.firstName}
                        onChange={(e) =>
                          updateSignUpCommunityData("firstName", e.target.value)
                        }
                        isInvalid={
                          !isInitialLoad &&
                          !validationRegex.firstName.test(
                            signUpCommunityData.firstName
                          )
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid first name (minimum 2 characters,
                        alphabetic characters only).
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="LName"
                      label="Last Name"
                    >
                      <Form.Control
                        required
                        type="text"
                        placeholder="Last Name"
                        value={signUpCommunityData.lastName}
                        onChange={(e) =>
                          updateSignUpCommunityData("lastName", e.target.value)
                        }
                        isInvalid={
                          !isInitialLoad &&
                          !validationRegex.lastName.test(
                            signUpCommunityData.lastName
                          )
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid last name (minimum 2 characters,
                        alphabetic characters only).
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="EmailAddress"
                      label="Email Address"
                    >
                      <Form.Control
                        required
                        type="email"
                        placeholder="Email Address"
                        value={signUpCommunityData.emailId}
                        onBlur={validateEmail}
                        onChange={(e) =>
                          updateSignUpCommunityData("emailId", e.target.value)
                        }
                        isInvalid={isEmailInvalid}
                      />
                      <Form.Control.Feedback type="invalid">
                        {invalidEmailMsg}
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="PhoneNumber"
                      label="Phone Number"
                    >
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry={countryCode}
                        placeholder="Enter phone number"
                        value={signUpCommunityData.phoneNo}
                        onChange={(e) =>
                          updateSignUpCommunityData("phoneNo", e)
                        }
                        rules={{ required: true }}
                        onBlur={handlePhoneValidation}
                      />
                      {!isInitialLoad && !phoneValidated && (
                        <Form.Text className="text-danger">
                          Please provide valid phone number
                        </Form.Text>
                      )}
                    </FloatingLabel>
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="DateOBirth"
                      label="Date of Birth"
                    >
                      <DatePicker
                        value={signUpCommunityData.dob}
                        onChange={(e) => updateSignUpCommunityData("dob", e)}
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                        maxDate={maxDate}
                        minDate={minDate}
                        required
                        onInvalidChange={() => setDobValidated(false)}
                      />
                      {!isInitialLoad && !dobValidated && (
                        <Form.Text className="text-danger">
                          Please provide valid dob
                        </Form.Text>
                      )}
                    </FloatingLabel>
                    <FloatingLabel as={Col} md="12" controlId="Age" label="Age">
                      <Form.Control
                        type="number"
                        placeholder="Age"
                        value={signUpCommunityData.age}
                        onChange={(e) =>
                          updateSignUpCommunityData("age", e.target.value)
                        }
                        min={18}
                        max={70}
                        isInvalid={
                          signUpCommunityData.age === "" && !isInitialLoad
                            ? true
                            : false
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide valid age
                      </Form.Control.Feedback>
                    </FloatingLabel>
                    <Form.Group
                      as={Col}
                      md="12"
                      className="radios-btns"
                      controlId="Gender"
                    >
                      <Form.Label>Gender</Form.Label>
                      <div className="radios-btns-gender">
                        <Form.Check
                          inline
                          label="Male"
                          name="Gender"
                          type="radio"
                          id="Male"
                          value="Male"
                          checked={signUpCommunityData.gender === "Male"}
                          onChange={(e) =>
                            updateSignUpCommunityData("gender", e.target.value)
                          }
                        />
                        <Form.Check inline name="Gender" id="Female">
                          <Form.Check.Input
                            type="radio"
                            value="Female"
                            checked={signUpCommunityData.gender === "Female"}
                            onChange={(e) =>
                              updateSignUpCommunityData(
                                "gender",
                                e.target.value
                              )
                            }
                            isInvalid={
                              !isInitialLoad && signUpCommunityData.gender == ""
                            }
                          />
                          <Form.Check.Label>Female</Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            Please fill this field
                          </Form.Control.Feedback>
                        </Form.Check>
                      </div>
                    </Form.Group>
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="Location"
                      label="Country currently living in"
                    >
                      <Form.Control
                        required
                        type="text"
                        placeholder="Country currently living in"
                        value={signUpCommunityData.location}
                        onChange={(e) =>
                          updateSignUpCommunityData("location", e.target.value)
                        }
                        isInvalid={
                          signUpCommunityData.location == "" ? true : false
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide country currently living in
                      </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="City"
                      label="City currently living in"
                    >
                      <Typeahead
                        id="membership_city"
                        labelKey="name"
                        options={cities}
                        placeholder="city living in"
                        selected={selectedCity}
                        isInvalid={
                          (!selectedCity || selectedCity.length == 0) &&
                          !isInitialLoad
                            ? true
                            : false
                        }
                        onChange={handleCityChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide name of the city you live in
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    {signUpCommunityData.qna.length > 0 &&
                      signUpCommunityData.qna.map(
                        (ques, i) =>
                          firstPageQ.includes(ques.id) &&
                          (ques.field_type === "radio_text" ? (
                            <Form.Group
                              as={Col}
                              md="12"
                              className="radios-btns"
                              controlId={ques.id}
                              key={ques.id}
                            >
                              <Form.Label>{ques.question}</Form.Label>
                              <div className="radios-btns-gender">
                                {ques.option &&
                                  ques.option.split("~").map((opt, indx) => {
                                    const isChecked =
                                      ques.answer &&
                                      (ques.answer === opt ||
                                        (ques.answer.includes("^") &&
                                          ques.answer.includes(opt.trim())));
                                    return (
                                      <div key={`${ques.id}${indx}`}>
                                        <Form.Check
                                          inline
                                          name={`qo${indx}${ques.id}`}
                                          id={`${indx}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="radio"
                                            value={opt}
                                            checked={isChecked}
                                            onChange={(e) =>
                                              updateQnA(
                                                ques,
                                                e.target.value,
                                                null
                                              )
                                            }
                                            isInvalid={
                                              !isInitialLoad &&
                                              ques.answer === ""
                                            }
                                          />
                                          <Form.Check.Label>
                                            {opt}
                                          </Form.Check.Label>
                                          <Form.Control.Feedback type="invalid">
                                            Please fill this field
                                          </Form.Control.Feedback>
                                        </Form.Check>
                                      </div>
                                    );
                                  })}
                              </div>
                              <div>
                                {ques.option &&
                                  ques.option.split("~").map((opt, indx) =>
                                    (opt.trim() === "Other" ||
                                      (ques.id === 14 &&
                                        opt.trim() === "Yes")) &&
                                    ques.answer &&
                                    (ques.answer.includes("Other") ||
                                      (ques.id === 14 &&
                                        ques.answer.includes("Yes"))) ? (
                                      <>
                                        <Form.Control
                                          key={`QT_${indx}_${ques.id}`}
                                          type="text"
                                          value={
                                            ques.answer.split("^")[1] || ""
                                          }
                                          onChange={(e) =>
                                            updateQnA(
                                              ques,
                                              ques.id === 14
                                                ? "Yes^" + e.target.value
                                                : "Other^" + e.target.value,
                                              null
                                            )
                                          }
                                          isInvalid={
                                            !isInitialLoad &&
                                            (ques.answer.split("^")[1] ===
                                              undefined ||
                                              ques.answer.split("^")[1] === "")
                                          }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please fill this field
                                        </Form.Control.Feedback>
                                      </>
                                    ) : null
                                  )}
                              </div>
                            </Form.Group>
                          ) : ques.field_type === "radio" ? (
                            <Form.Group
                              as={Col}
                              md="12"
                              className="radios-btns"
                              controlId={ques.id}
                              key={ques.id}
                            >
                              <Form.Label>{ques.question}</Form.Label>
                              <div className="radios-btns-gender">
                                {ques.option !== null &&
                                  ques.option.split("~").map((o, i) => (
                                    <div key={`${ques.id}${i}`}>
                                      {ques.option.split("~").length - 1 ===
                                      i ? (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="radio"
                                            value={o}
                                            checked={
                                              ques.answer != null &&
                                              ques.answer == o
                                            }
                                            onChange={(e) =>
                                              updateQnA(ques, e.target.value)
                                            }
                                            isInvalid={
                                              !isInitialLoad &&
                                              ques.answer === ""
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                          <Form.Control.Feedback type="invalid">
                                            Please fill this field
                                          </Form.Control.Feedback>
                                        </Form.Check>
                                      ) : (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="radio"
                                            value={o}
                                            checked={
                                              ques.answer != null &&
                                              ques.answer == o
                                            }
                                            onChange={(e) =>
                                              updateQnA(ques, e.target.value)
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      )}
                                    </div>
                                  ))}
                              </div>
                            </Form.Group>
                          ) : ques.field_type === "text" ? (
                            <FloatingLabel
                              as={Col}
                              md="12"
                              controlId={`Q_${ques.id}`}
                              label={ques.question}
                              key={ques.id}
                            >
                              <Form.Control
                                type="text"
                                placeholder={ques.question}
                                value={ques.answer ?? ""}
                                onChange={(e) =>
                                  updateQnA(ques, e.target.value, null)
                                }
                              />
                            </FloatingLabel>
                          ) : ques.field_type === "select_2" ? (
                            <>
                              {ques.id === 40 ? (
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  className="radios-btns signup-select-salary"
                                  controlId={ques.id}
                                  key={ques.id}
                                >
                                  <Form.Label column>
                                    {ques.question}
                                  </Form.Label>
                                  <div className="row height-feet-inches">
                                    <div className="col-md-6">
                                      <Form.Select
                                        aria-label={ques.question}
                                        size="sm"
                                        value={selectedCurrency}
                                        onChange={(e) =>
                                          handleCurrencyChange(e.target.value)
                                        }
                                      >
                                        <option key="currency">Currency</option>
                                        {currencyList.map((curr, i) => (
                                          <option value={curr.enr_name}>
                                            {curr.enr_name}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </div>
                                    <div className="col-md-6">
                                      {" "}
                                      <Form.Select
                                        className="signup-select"
                                        aria-label={ques.question}
                                        size="sm"
                                        value={
                                          ques.answer == null ? "" : ques.answer
                                        }
                                        onChange={(e) =>
                                          updateQnA(ques, e.target.value, null)
                                        }
                                        isInvalid={
                                          !isInitialLoad && ques.answer === ""
                                        }
                                      >
                                        <option key="salary"> </option>
                                        {salaryList.map((sal, i) => (
                                          <option
                                            value={sal.salary_id}
                                            key={`sal_opt_${i}`}
                                          >
                                            {sal.salary_slab}
                                          </option>
                                        ))}
                                      </Form.Select>{" "}
                                    </div>

                                    <Form.Control.Feedback type="invalid">
                                      Please fill this field
                                    </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              ) : (
                                <Form.Group
                                  className="radios-btns"
                                  as={Col}
                                  md="12"
                                  controlId={"Q_" + ques.id}
                                  key={"QS2_" + ques.id}
                                >
                                  <Form.Label>{ques.question}</Form.Label>
                                  <div className="row height-feet-inches">
                                    <div className="col-sm-5">
                                      <Form.Select
                                        aria-label={ques.question}
                                        size="sm"
                                        sm="5"
                                        value={
                                          ques.answer == null
                                            ? ""
                                            : `${
                                                ques.answer.split(".")[0]
                                              } feet`
                                        }
                                        onChange={(e) =>
                                          handleHeight(ques, e.target.value)
                                        }
                                        isInvalid={
                                          !isInitialLoad && ques.answer === ""
                                        }
                                      >
                                        <option key="feet">Feet</option>
                                        {feet.map((f, i) => (
                                          <option key={"QSF_" + i}>{f}</option>
                                        ))}
                                      </Form.Select>
                                    </div>
                                    <Form.Control.Feedback
                                      type="invalid"
                                      sm="12"
                                    >
                                      Please fill this field
                                    </Form.Control.Feedback>{" "}
                                    <div className="col-sm-5">
                                      <Form.Select
                                        aria-label={ques.question}
                                        size="sm"
                                        sm="2"
                                        value={
                                          ques.answer == null
                                            ? ""
                                            : ques.answer.split(".")[1] == 0 ||
                                              ques.answer.split(".")[1] == 1
                                            ? `${
                                                ques.answer.split(".")[1]
                                              } inch`
                                            : `${
                                                ques.answer.split(".")[1]
                                              } inches`
                                        }
                                        onChange={(e) =>
                                          handleHeight(ques, e.target.value)
                                        }
                                        isInvalid={
                                          !isInitialLoad && ques.answer === ""
                                        }
                                      >
                                        <option key="inch">Inches</option>
                                        {inches.map((f, i) => (
                                          <option key={"QSI_" + i}>{f}</option>
                                        ))}
                                      </Form.Select>
                                    </div>
                                    <Form.Control.Feedback
                                      type="invalid"
                                      sm="12"
                                    >
                                      Please fill this field
                                    </Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              )}
                            </>
                          ) : ques.field_type === "radio_select" ? (
                            <Form.Group
                              as={Col}
                              md="12"
                              className="radios-btns"
                              controlId={ques.id}
                              key={ques.id}
                            >
                              <Form.Label>{ques.question}</Form.Label>
                              <div className="radios-btns-gender">
                                {ques.option != null &&
                                  ques.option.split("~").map((o, i) => (
                                    <div key={`RT_${i}_${ques.id}`}>
                                      {ques.option.split("~").length - 1 ===
                                      i ? (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="radio"
                                            value={o}
                                            checked={
                                              (ques.answer != null &&
                                                ques.answer == o) ||
                                              (ques.answer != null &&
                                                ques.answer.indexOf("^") !==
                                                  -1 &&
                                                ques.answer.indexOf(
                                                  o.trim()
                                                ) !== -1)
                                            }
                                            onChange={(e) =>
                                              updateQnA(
                                                ques,
                                                e.target.value,
                                                null
                                              )
                                            }
                                            isInvalid={
                                              !isInitialLoad &&
                                              ques.answer === ""
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                          <Form.Control.Feedback type="invalid">
                                            Please fill this field
                                          </Form.Control.Feedback>
                                        </Form.Check>
                                      ) : (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="radio"
                                            value={o}
                                            checked={
                                              (ques.answer != null &&
                                                ques.answer == o) ||
                                              (ques.answer != null &&
                                                ques.answer.indexOf("^") !==
                                                  -1 &&
                                                ques.answer.indexOf(
                                                  o.trim()
                                                ) !== -1)
                                            }
                                            onChange={(e) =>
                                              updateQnA(
                                                ques,
                                                e.target.value,
                                                null
                                              )
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      )}
                                    </div>
                                  ))}
                              </div>
                              {ques.answer != null &&
                                ques.answer.indexOf("Yes") !== -1 && (
                                  <>
                                    <Form.Group
                                      as={Col}
                                      md="12"
                                      className="radios-btns signup-select-salary"
                                      controlId={ques.id}
                                      key={ques.id}
                                    >
                                      <Typeahead
                                        multiple
                                        id="membership_country"
                                        labelKey="name"
                                        options={countryList}
                                        placeholder={ques.question}
                                        selected={selectedCountries}
                                        isInvalid={
                                          !isInitialLoad && ques.answer === ""
                                        }
                                        onChange={(e) =>
                                          handleCountryChange(ques, e)
                                        }
                                        required
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please fill this field
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </>
                                )}
                            </Form.Group>
                          ) : ques.field_type === "radio_textarea_radio" ? (
                            <>
                              <Form.Group
                                key={"QRT_" + ques.id}
                                className="radios-btns"
                                as={Col}
                                md="12"
                                controlId={"Q_" + ques.id}
                              >
                                <Form.Label>{ques.question}</Form.Label>
                                <div className="radios-btns-gender">
                                  {ques.option != null &&
                                    ques.option.split("~").map((o, i) => (
                                      <div key={`RT_${i}_${ques.id}`}>
                                        {ques.option.split("~").length - 1 ===
                                        i ? (
                                          <Form.Check
                                            inline
                                            name={`qo${i}${ques.id}`}
                                            id={`${i}${ques.id}`}
                                          >
                                            <Form.Check.Input
                                              type="radio"
                                              value={o.split("^")[0]}
                                              checked={
                                                (ques.answer != null &&
                                                  ques.answer ==
                                                    o.split("^")[0]) ||
                                                (ques.answer != null &&
                                                  ques.answer.indexOf("^") !==
                                                    -1 &&
                                                  ques.answer.indexOf(
                                                    o.split("^")[0].trim()
                                                  ) !== -1)
                                              }
                                              onChange={(e) =>
                                                updateQnA(
                                                  ques,
                                                  e.target.value,
                                                  null
                                                )
                                              }
                                              isInvalid={
                                                !isInitialLoad &&
                                                ques.answer === ""
                                              }
                                            />
                                            <Form.Check.Label>
                                              {o.split("^")[0]}
                                            </Form.Check.Label>
                                            <Form.Control.Feedback type="invalid">
                                              Please fill this field
                                            </Form.Control.Feedback>
                                          </Form.Check>
                                        ) : (
                                          <Form.Check
                                            inline
                                            name={`qo${i}${ques.id}`}
                                            id={`${i}${ques.id}`}
                                          >
                                            <Form.Check.Input
                                              type="radio"
                                              value={o.split("^")[0]}
                                              checked={
                                                (ques.answer != null &&
                                                  ques.answer ==
                                                    o.split("^")[0]) ||
                                                (ques.answer != null &&
                                                  ques.answer.indexOf("^") !==
                                                    -1 &&
                                                  ques.answer.indexOf("Yes") !==
                                                    -1)
                                              }
                                              onChange={(e) =>
                                                updateQnA(
                                                  ques,
                                                  e.target.value,
                                                  null
                                                )
                                              }
                                            />
                                            <Form.Check.Label>
                                              {o.split("^")[0]}
                                            </Form.Check.Label>
                                          </Form.Check>
                                        )}
                                      </div>
                                    ))}
                                </div>
                                {ques.answer != null &&
                                  ques.answer.indexOf("Yes") !== -1 && (
                                    <>
                                      <Form.Control
                                        as="textarea"
                                        // placeholder={ques.question}
                                        rows={3}
                                        value={
                                          ques.answer == null
                                            ? ""
                                            : ques.answer.split("^")[1]
                                        }
                                        onChange={(e) =>
                                          updateQnA(
                                            ques,
                                            "Yes^" + e.target.value,
                                            null
                                          )
                                        }
                                        isInvalid={
                                          !isInitialLoad && ques.answer === ""
                                        }
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please fill this field
                                      </Form.Control.Feedback>
                                    </>
                                  )}
                              </Form.Group>
                            </>
                          ) : ques.field_type === "textarea" ? (
                            <Form.Group
                              key={"QTA_" + ques.id}
                              className="radios-btns"
                              controlId={"QTA_" + ques.id}
                              as={Col}
                              md="12"
                            >
                              <Form.Label>{ques.question}</Form.Label>
                              <Form.Control
                                as="textarea"
                                // placeholder={ques.question}
                                rows={3}
                                value={
                                  ques.answer == "null" || ques.answer == null
                                    ? ""
                                    : ques.answer
                                }
                                onChange={(e) =>
                                  updateQnA(ques, e.target.value, null)
                                }
                                isInvalid={!isInitialLoad && ques.answer === ""}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please fill this field
                              </Form.Control.Feedback>
                            </Form.Group>
                          ) : ques.field_type === "checkbox" ? (
                            <Form.Group
                              as={Col}
                              key={"Q_" + ques.id}
                              className="radios-btns"
                              md="12"
                              controlId={"Q_" + ques.id}
                            >
                              <Form.Label key={"QL_" + ques.id}>
                                {ques.question}
                              </Form.Label>
                              <div className="radios-btns-gender">
                                {ques.option != null &&
                                  ques.option.split("~").map((o, i) => (
                                    <div key={`QR_${i}_${ques.id}`}>
                                      {ques.option.split("~").length - 1 ===
                                      i ? (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="checkbox"
                                            value={o}
                                            checked={
                                              ques.answer != null &&
                                              ques.answer.split("~").includes(o)
                                            }
                                            onChange={(e) =>
                                              handleCheckboxChange(e, ques)
                                            }
                                            isInvalid={
                                              !isInitialLoad &&
                                              ques.answer === ""
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                          <Form.Control.Feedback type="invalid">
                                            Please fill this field
                                          </Form.Control.Feedback>
                                        </Form.Check>
                                      ) : (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="checkbox"
                                            value={o}
                                            checked={
                                              ques.answer != null &&
                                              ques.answer.split("~").includes(o)
                                            }
                                            onChange={(e) =>
                                              handleCheckboxChange(e, ques)
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      )}
                                    </div>
                                  ))}
                              </div>
                            </Form.Group>
                          ) : ques.field_type === "checkbox_text" ? (
                            <Form.Group
                              as={Col}
                              md="12"
                              key={"QCT_" + ques.id}
                              className="radios-btns"
                              controlId={"Q_" + ques.id}
                            >
                              <Form.Label column>{ques.question}</Form.Label>
                              <div className="radios-btns-gender">
                                {ques.option != null &&
                                  ques.option.split("~").map((o, i) => (
                                    <div key={`CT_${i}_${ques.id}`}>
                                      {ques.option.split("~").length - 1 ===
                                      i ? (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="checkbox"
                                            value={o}
                                            checked={
                                              ques.answer != null &&
                                              ques.answer
                                                .split("~")
                                                .some((part) =>
                                                  part.includes(o)
                                                )
                                            }
                                            onChange={(e) =>
                                              handleCheckboxChange(e, ques)
                                            }
                                            isInvalid={
                                              !isInitialLoad &&
                                              ques.answer === ""
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                          <Form.Control.Feedback type="invalid">
                                            Please fill this field
                                          </Form.Control.Feedback>
                                        </Form.Check>
                                      ) : (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="checkbox"
                                            value={o}
                                            checked={
                                              ques.answer != null &&
                                              ques.answer.split("~").includes(o)
                                            }
                                            onChange={(e) =>
                                              handleCheckboxChange(e, ques)
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      )}
                                    </div>
                                  ))}
                              </div>
                              {ques.answer != null &&
                                ques.answer.indexOf("Other") !== -1 && (
                                  <>
                                    <Form.Control
                                      key={`QTO_${ques.id}`}
                                      type="text"
                                      value={
                                        ques.answer == null
                                          ? ""
                                          : ques.answer.split("^")[1] !==
                                            undefined
                                          ? ques.answer
                                              .split("^")[1]
                                              .split("~")[0]
                                          : ""
                                      }
                                      onChange={(e) => handleOtherText(e, ques)}
                                      isInvalid={
                                        !isInitialLoad && ques.answer === ""
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please fill this field
                                    </Form.Control.Feedback>
                                  </>
                                )}
                            </Form.Group>
                          ) : (
                            ""
                          ))
                      )}
                  </Row>
                  <Row
                    className="mb-3"
                    style={{
                      display: showRomantic ? "" : "none",
                    }}
                  >
                    {signUpCommunityData.qna.length > 0 &&
                      signUpCommunityData.qna.map(
                        (ques, i) =>
                          secondPageQ.includes(ques.id) &&
                          (ques.field_type === "radio_text" ? (
                            <Form.Group
                              as={Col}
                              md="12"
                              className="radios-btns"
                              controlId={ques.id}
                              key={ques.id}
                            >
                              <Form.Label>{ques.question}</Form.Label>
                              <div className="radios-btns-gender">
                                {ques.option &&
                                  ques.option.split("~").map((opt, indx) => {
                                    const isChecked =
                                      ques.answer &&
                                      (ques.answer === opt ||
                                        (ques.answer.includes("^") &&
                                          ques.answer.includes(opt.trim())));
                                    return (
                                      <div key={`${ques.id}${indx}`}>
                                        <Form.Check
                                          inline
                                          name={`qo${indx}${ques.id}`}
                                          id={`${indx}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="radio"
                                            value={opt}
                                            checked={isChecked}
                                            onChange={(e) =>
                                              updateQnA(
                                                ques,
                                                e.target.value,
                                                null
                                              )
                                            }
                                            isInvalid={
                                              !isInitialLoad &&
                                              ques.answer === ""
                                            }
                                          />
                                          <Form.Check.Label>
                                            {opt}
                                          </Form.Check.Label>
                                          <Form.Control.Feedback type="invalid">
                                            Please fill this field
                                          </Form.Control.Feedback>
                                        </Form.Check>
                                      </div>
                                    );
                                  })}
                              </div>
                              <div>
                                {ques.option &&
                                  ques.option.split("~").map((opt, indx) =>
                                    opt.trim() === "Other" &&
                                    ques.answer &&
                                    ques.answer.includes("Other") ? (
                                      <>
                                        <Form.Control
                                          key={`QT_${indx}_${ques.id}`}
                                          type="text"
                                          value={
                                            ques.answer.split("^")[1] || ""
                                          }
                                          onChange={(e) =>
                                            updateQnA(
                                              ques,
                                              "Other^" + e.target.value,
                                              null
                                            )
                                          }
                                          isInvalid={
                                            ques.answer.split("^")[1] === ""
                                          }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please fill this field
                                        </Form.Control.Feedback>
                                      </>
                                    ) : null
                                  )}
                              </div>
                            </Form.Group>
                          ) : ques.field_type === "radio" ? (
                            <Form.Group
                              as={Col}
                              md="12"
                              className="radios-btns"
                              controlId={ques.id}
                              key={ques.id}
                            >
                              <Form.Label>{ques.question}</Form.Label>
                              <div className="radios-btns-gender">
                                {ques.option !== null &&
                                  ques.option.split("~").map((o, i) => (
                                    <div key={`${ques.id}${i}`}>
                                      {ques.option.split("~").length - 1 ===
                                      i ? (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="radio"
                                            value={o}
                                            checked={
                                              ques.answer != null &&
                                              ques.answer == o
                                            }
                                            onChange={(e) =>
                                              updateQnA(ques, e.target.value)
                                            }
                                            isInvalid={
                                              !isInitialLoad &&
                                              ques.answer === ""
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                          <Form.Control.Feedback type="invalid">
                                            Please fill this field
                                          </Form.Control.Feedback>
                                        </Form.Check>
                                      ) : (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="radio"
                                            value={o}
                                            checked={
                                              ques.answer != null &&
                                              ques.answer == o
                                            }
                                            onChange={(e) =>
                                              updateQnA(ques, e.target.value)
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      )}
                                    </div>
                                  ))}
                              </div>
                            </Form.Group>
                          ) : ques.field_type === "select_2" ? (
                            <Form.Group
                              className="radios-btns"
                              as={Col}
                              md="12"
                              controlId={"Q_" + ques.id}
                              key={"Q_" + ques.id}
                            >
                              <Form.Label key={"QL_" + ques.id}>
                                {ques.question}
                              </Form.Label>
                              <div className="age-range-form-to">
                                <Form.Select
                                  key={"QS_" + ques.id}
                                  aria-label={ques.question}
                                  size="sm"
                                  value={
                                    ques.answer == null
                                      ? ""
                                      : `${ques.answer.split(",")[0]}`
                                  }
                                  onChange={(e) =>
                                    handleAge(ques, e.target.value, "from")
                                  }
                                >
                                  <option key="from">From</option>
                                  {ageArray.map((f, i) => (
                                    <option key={"QS_" + i}>{f}</option>
                                  ))}
                                </Form.Select>
                                {"–"}
                                <Form.Select
                                  key={"QS1_" + ques.id}
                                  aria-label={ques.question}
                                  size="sm"
                                  value={
                                    ques.answer == null
                                      ? ""
                                      : `${ques.answer.split(",")[1]}`
                                  }
                                  onChange={(e) =>
                                    handleAge(ques, e.target.value, "to")
                                  }
                                  isInvalid={
                                    !isInitialLoad &&
                                    (ques.answer === "" ||
                                      ques.answer.split(",")[1] === "")
                                  }
                                >
                                  <option key="to">To</option>
                                  {ageArray.map((f, i) => (
                                    <option key={"QS0_" + i}>{f}</option>
                                  ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  Please fill this field
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          ) : ques.field_type === "textarea" ? (
                            <Form.Group
                              key={"QTA_" + ques.id}
                              className="radios-btns"
                              controlId={"QTA_" + ques.id}
                              as={Col}
                              md="12"
                            >
                              <Form.Label>{ques.question}</Form.Label>
                              <Form.Control
                                as="textarea"
                                // placeholder={ques.question}
                                rows={3}
                                value={
                                  ques.answer == "null" || ques.answer == null
                                    ? ""
                                    : ques.answer
                                }
                                onChange={(e) =>
                                  updateQnA(ques, e.target.value, null)
                                }
                                isInvalid={!isInitialLoad && ques.answer === ""}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please fill this field
                              </Form.Control.Feedback>
                            </Form.Group>
                          ) : ques.field_type === "checkbox" ? (
                            <Form.Group
                              as={Col}
                              key={"Q_" + ques.id}
                              className="radios-btns"
                              md="12"
                              controlId={"Q_" + ques.id}
                            >
                              <Form.Label key={"QL_" + ques.id}>
                                {ques.question}
                              </Form.Label>
                              <div className="radios-btns-gender">
                                {ques.option != null &&
                                  ques.option.split("~").map((o, i) => (
                                    <div key={`QR_${i}_${ques.id}`}>
                                      {ques.option.split("~").length - 1 ===
                                      i ? (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="checkbox"
                                            value={o}
                                            checked={
                                              ques.answer != null &&
                                              ques.answer.split("~").includes(o)
                                            }
                                            onChange={(e) =>
                                              handleCheckboxChange(e, ques)
                                            }
                                            isInvalid={
                                              !isInitialLoad &&
                                              ques.answer === ""
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                          <Form.Control.Feedback type="invalid">
                                            Please fill this field
                                          </Form.Control.Feedback>
                                        </Form.Check>
                                      ) : (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="checkbox"
                                            value={o}
                                            checked={
                                              ques.answer != null &&
                                              ques.answer.split("~").includes(o)
                                            }
                                            onChange={(e) =>
                                              handleCheckboxChange(e, ques)
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      )}
                                    </div>
                                  ))}
                              </div>
                            </Form.Group>
                          ) : (
                            ""
                          ))
                      )}
                  </Row>
                  <div style={{ display: showRomanticUpload ? "" : "none" }}>
                    <Container>
                      <Row>
                        <Col lg={12} md={12}>
                          <div
                            className="upload-document-left"
                            title="Click to upload"
                          >
                            <div
                              className="document-item"
                              onClick={() => handlePicUpload()}
                            >
                              <img
                                src={
                                  signUpCommunityData.profilePic === ""
                                    ? DocIcon
                                    : signUpCommunityData.profilePic
                                }
                                alt=""
                                style={{ maxWidth: "100%" }}
                              />
                              <div className="document-input">
                                <input
                                  id="picfile"
                                  type="file"
                                  accept=".jpg,.jpeg,.png"
                                  onChange={(e) => handleUpload(e, "pic")}
                                  title="Click to upload"
                                />
                              </div>
                            </div>
                            <div className="document-info">
                              <p>
                                {
                                  "Please share a recent photograph (< 5MB).(Click above to upload)"
                                }
                              </p>
                              <span className="error" style={{ color: "red" }}>
                                {picError}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12}>
                          <div
                            className="upload-document-left"
                            title="Click to upload"
                          >
                            <div
                              className="document-item"
                              onClick={() => handleProofUpload()}
                            >
                              {!docTypePDF ? (
                                <img
                                  src={
                                    signUpCommunityData.proofDoc === ""
                                      ? DocIcon
                                      : signUpCommunityData.proofDoc
                                  }
                                  alt=""
                                  style={{ maxWidth: "100%" }}
                                  title="Click to upload"
                                />
                              ) : (
                                <FiletypePdf size="10em" />
                              )}

                              <div className="document-input">
                                <input
                                  id="prooffile"
                                  type="file"
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  onChange={(e) => handleUpload(e, "doc")}
                                  title="Click to upload"
                                />
                              </div>
                            </div>
                            <div className="document-info">
                              <p>
                                {
                                  "Please share a copy of government approved identification document (< 5MB). (Click above to upload)"
                                }
                              </p>
                              <span className="error" style={{ color: "red" }}>
                                {docError}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>

                  <Row
                    className="mb-3"
                    style={{
                      display:
                        (commonQues && !romanticOpt) || showRomanticUpload
                          ? ""
                          : "none",
                    }}
                  >
                    {" "}
                    <Form.Group
                      as={Col}
                      md="12"
                      className="radios-btns-gender tnc-checks"
                      controlId="consent-control"
                    >
                      <Form.Check inline name="consent" id="consent">
                        <Form.Check.Input
                          type="checkbox"
                          value="consented"
                          checked={signUpCommunityData.consent === "consented"}
                          onChange={(e) =>
                            updateSignUpCommunityData("consent", e.target.value)
                          }
                          isInvalid={
                            !isInitialLoad && signUpCommunityData.consent === ""
                          }
                          required
                        />
                        <Form.Check.Label>
                          I agree that information I have provided here is true
                          and accurate
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          Please fill this field
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="12"
                      className="radios-btns-gender tnc-checks"
                      controlId="tnc"
                    >
                      <Form.Check inline name="tnc" id="tnc">
                        <Form.Check.Input
                          type="checkbox"
                          value="agreed"
                          checked={signUpCommunityData.tnc === "agreed"}
                          onChange={(e) =>
                            updateSignUpCommunityData("tnc", e.target.value)
                          }
                          isInvalid={
                            !isInitialLoad && signUpCommunityData.tnc === ""
                          }
                          required
                        />
                        <Form.Check.Label>
                          I am in full agreement with{" "}
                          <a
                            href="https://www.two2tango.com/TermsConditions"
                            target="_blank"
                            rel="noreferrer"
                          >
                            terms & conditions
                          </a>{" "}
                          and{" "}
                          <a
                            href="https://www.two2tango.com/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                          >
                            privacy policy
                          </a>
                        </Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          Please fill this field
                        </Form.Control.Feedback>
                      </Form.Check>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      className="my-membership-inout-row"
                      controlId="captcha"
                    >
                      <Form.Label column sm="12">
                        <LoadCanvasTemplateNoReload />
                      </Form.Label>
                      <Col md="12">
                        <Form.Control
                          type="text"
                          placeholder="captcha"
                          value={signUpCommunityData.captcha}
                          onChange={(e) =>
                            updateSignUpCommunityData("captcha", e.target.value)
                          }
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Row>

                  <div
                    className="mb-3"
                    style={{
                      display:
                        romanticOpt || showRomantic || showRomanticUpload
                          ? ""
                          : "none",
                    }}
                  >
                    <div
                      className="next-prev-btn-community"
                      style={{
                        display: showRomantic ? "" : "none",
                      }}
                    >
                      <Button
                        className="join-btn"
                        type="button"
                        onClick={() => handlePrevClic(1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="join-btn"
                        type="button"
                        onClick={() => onClickRomanticUpload()}
                      >
                        Next
                      </Button>
                    </div>
                    <div
                      className="next-prev-btn-community"
                      style={{
                        display: showRomanticUpload ? "" : "none",
                      }}
                    >
                      <Button
                        className="join-btn"
                        type="button"
                        onClick={() => handlePrevClic(2)}
                      >
                        Previous
                      </Button>
                      <Button className="join-btn" type="submit">
                        JOIN
                        <Loader visible={showLoader} />
                      </Button>
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                      <span className="error" style={{ color: "red" }}>
                        {formSubmitError}
                      </span>
                    </div>
                    <div
                      style={{
                        display: commonQues ? "" : "none",
                      }}
                    >
                      <Button
                        className="join-btn"
                        type="button"
                        onClick={() => onClickRomantic()}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                  <div
                    className="mb-3"
                    style={{
                      display: commonQues && !romanticOpt ? "" : "none",
                    }}
                  >
                    <Button className="join-btn" type="submit">
                      JOIN
                      <Loader visible={showLoader} />
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
