import React, { useState } from "react";
import "./Login.css";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import LoginRightImg from "../../images/login-right-img.jpg";
import BookConsultation from "../../Components/BookConsultation/BookConsultation";
import { ValidateLogin } from "../../apis/UserAPI";
import md5 from "md5";
import { Link, useNavigate } from "react-router-dom";
import { AlertMessage } from "../../Components/AlertMessage/AlertMessage";
import Loader from "../../Components/Spinner/Loader";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../slices/authSlice";
import { StoreLocalUser } from "../../slices/authLocal";
import { UpdateUserSectionStatus } from "../../apis/ProfileAPI";
import { Helmet } from "react-helmet-async";

export default function Login() {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [alertData, setAlertData] = useState({
    variant: "",
    message: "",
    showAlert: false,
    date: Date.now(),
  });

  const [loginDetail, setLoginDetail] = useState({
    username: "",
    password: "",
  });

  const [showLoader, setShowLoader] = useState(false);

  const updateLoginDetail = (key, value) => {
    setLoginDetail((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      try {
        setShowLoader(true);
        let result = await ValidateLogin(
          loginDetail.username,
          md5(loginDetail.password)
        );
        dispatch(loginSuccess(result));
        StoreLocalUser(
          result.userId,
          result.payId,
          result.userName,
          result.userGender,
          result.profileImg,
          result.modifiedDate,
          result.membershipExpired,
          result.token
        );
        setShowLoader(false);
        if (result.membershipExpired == 1) {
          navigate(`/Payment?uid=${encodeURIComponent(result.payId)}&type=renew`);
        } else {
          await UpdateUserSectionStatus();
          navigate("/MyProfile");
        }
      } catch {
        setShowLoader(false);
        setAlertData({
          variant: "Dark",
          message: "Invalid credentials",
          showAlert: true,
          date: Date.now(),
        });
      }
    }

    setValidated(true);
  };
  return (
    <>
    <Helmet>
        <title>Sign In - Two 2 Tango | Join a Global Community for Singles</title>
        <meta name="description" content="Sign in to Two 2 Tango and connect with a global community of singles. Find meaningful connections, join exclusive events, and explore personalized matchmaking." />
      </Helmet>
      <section className="login">
        <AlertMessage
          variant={alertData.variant}
          message={alertData.message}
          show={alertData.showAlert}
          delay={3000}
          date={alertData.date}
        />
        <Container fluid>
          <Row>
            <Col lg={6}>
              <div className="login-left signup-form">
                <h2 data-aos="zoom-in" data-aos-duration="1000">
                  Welcome back!
                </h2>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <Row className="mb-3">
                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="EmailAddress"
                      label="Email Address"
                    >
                      <Form.Control
                        required
                        type="email"
                        placeholder="Email Address"
                        value={loginDetail.username}
                        onChange={(e) =>
                          updateLoginDetail("username", e.target.value)
                        }
                        autoComplete="new-password"
                      />
                    </FloatingLabel>

                    <FloatingLabel
                      as={Col}
                      md="12"
                      controlId="Password"
                      label="Password"
                    >
                      <Form.Control
                        required
                        type="password"
                        placeholder="Password"
                        value={loginDetail.password}
                        onChange={(e) =>
                          updateLoginDetail("password", e.target.value)
                        }
                        autoComplete="new-password"
                      />
                    </FloatingLabel>
                    <div
                      className="col-12 forgot-password"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <Link to="/ForgotPassword">Forgot Password?</Link>
                    </div>
                  </Row>
                  <Button
                    className="login-btn"
                    type="submit"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    LOGIN
                    <Loader visible={showLoader} />
                  </Button>
                </Form>
              </div>
            </Col>
            <Col lg={6} className="p-0">
              <div className="login-right">
                <img src={LoginRightImg} alt="Tow To Tango" />
              </div>
            </Col>
          </Row>
        </Container>
        <BookConsultation />
      </section>
    </>
  );
}
