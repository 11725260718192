import React, { useEffect, useState } from "react";
import "./MyMembership.css";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { GetMyMembershipInfo } from "../../apis/ProfileAPI";
import { confirm } from "../../Components/ConfirmMessage/Confirmation";
import { CancelSubscription, UpdateSubscription } from "../../apis/StripeAPI";
import { AlertMessage } from "../../Components/AlertMessage/AlertMessage";
import { GetPackageByCurrency } from "../../apis/PackageAPI";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";

export default function MyMembership() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [membershipInfo, setMembershipInfo] = useState({
    packageName: "",
    membershipStartDate: "",
    membershipEndDate: "",
    membershipDuration: 12,
    membershipDaysLeft: 0,
    membershipCurrency: "INR",
    susbscriptionCancelled: false,
  });

  const [alertData, setAlertData] = useState({
    variant: "",
    message: "",
    showAlert: false,
    date: Date.now(),
  });

  const [packages, setPackages] = useState([]);
  const [disableBtn, setDisableBtn] = useState({
    cancel: false,
    update: false,
    upgrade: false,
    addon: false,
    renew: false,
  });
  const [userPkgId, setUserPkgId] = useState("");
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [maxDuration, setMaxDuration] = useState(0);

  useEffect(() => {
    async function GetMembershipInfo() {
      var result = await GetMyMembershipInfo();
      setMembershipInfo({
        packageName: result.packages,
        membershipStartDate: result.user_pkg_start_date,
        membershipEndDate: result.user_pkg_end_date,
        membershipDuration: result.package_duration,
        membershipDaysLeft: result.days_left,
        membershipCurrency: result.package_currency,
        susbscriptionCancelled: result.subscription_cancelled,
      });
      setUserPkgId(result.user_package_id);
      var pkgs = result.user_package_id.split(",");
      console.log(pkgs);
      setSelectedPackage(pkgs);
    }

    GetMembershipInfo();
  }, []);

  useEffect(() => {
    if (membershipInfo !== undefined && membershipInfo !== null) GetPackages();
    if (membershipInfo.membershipDuration === "1") {
      if (membershipInfo.susbscriptionCancelled) {
        setDisableBtn({
          cancel: true,
          update: true,
          upgrade: false,
          addon: false,
          renew: false,
        });
      }
    }
  }, [membershipInfo]);

  const GetPackages = async () => {
    var result = await GetPackageByCurrency(membershipInfo.membershipCurrency);
    var durs = result.map((x) => parseInt(x.duration));
    setMaxDuration(Math.max(...durs));
    var monthlyPackage = result.filter((x) => x.duration === "1");
    setPackages(monthlyPackage);
  };

  const handleCancelSubs = async (type) => {
    setDisableBtn((prevState) => ({ ...prevState, [type]: true }));
    if (
      await confirm(
        "Are you sure you want to cancel your subscription?",
        "Yes",
        "No",
        {
          title: "Cancel Subscription",
        }
      )
    ) {
      await CancelSubscription();
      setAlertData({
        variant: "Dark",
        message: "Subscription cancelled.",
        showAlert: true,
        date: Date.now(),
      });
      setDisableBtn((prevState) => ({ ...prevState, [type]: false }));
      window.location.reload();
    }
    setDisableBtn((prevState) => ({ ...prevState, [type]: false }));
  };

  const handleUpdateSubs = async (type, pkgCount) => {
    setDisableBtn((prevState) => ({ ...prevState, [type]: true }));
    var optionalPkg = packages.filter((x) => x.optional === 1);
    var msgBody =
      pkgCount === 1
        ? `Cost of add on Cupid's Concierge ($${optionalPkg[0].packageValue}) and will get automatically deducted from you saved card. Are you sure want to continue?`
        : "If you would like to remove Cupid's Concierge, please do so at the end of your subscription. There will be no refund applicable on cancellation of the concierge service in the middle of your subscription period. Are you sure want to continue?";
    var msgTitle =
      pkgCount === 1 ? "Add Cupid's Concierge" : "Remove Cupid's Concierge";
    if (await confirm(msgBody, "Yes", "No", { title: msgTitle })) {
      var pkgId = selectedPackage;
      if (pkgCount === 1) pkgId = [...selectedPackage, optionalPkg[0].id];
      else
        pkgId = selectedPackage.filter(
          (x) => x !== optionalPkg[0].id.toString()
        );
      await UpdateUserSubscription(type, pkgId);
    } else {
      setDisableBtn((prevState) => ({ ...prevState, [type]: false }));
    }
  };

  const UpdateUserSubscription = async (type, packageIds) => {
    var pkgIds = packageIds.map((x) => x).join(",");
    if (userPkgId == pkgIds) {
      setAlertData({
        variant: "Dark",
        message: "There is nothing to do here.",
        showAlert: true,
        date: Date.now(),
      });
      setDisableBtn((prevState) => ({ ...prevState, [type]: false }));
    } else {
      await UpdateSubscription(pkgIds);
      setDisableBtn((prevState) => ({ ...prevState, [type]: false }));
      setAlertData({
        variant: "Dark",
        message:
          "Subscription updated. It will reflect after invoice if to be paid.",
        showAlert: true,
        date: Date.now(),
      });
      window.location.reload();
    }
  };

  const handleRenew = async (type) => {
    setDisableBtn((prevState) => ({ ...prevState, [type]: true }));
    navigate(
      `/Payment?uid=${encodeURIComponent(
        user.payId
      )}&pid=${userPkgId}&type=${type}`
    );
  };

  const handleUpgrade = async (type) => {
    setDisableBtn((prevState) => ({ ...prevState, [type]: true }));
    var ppIds = selectedPackage.map((x) => x).join(",");
    navigate(
      `/Payment?uid=${encodeURIComponent(
        user.payId
      )}&ppid=${ppIds}&type=${type}`
    );
  };

  return (
    <>
    <Helmet>
        <title>Membership | Nuanced Matchmaking & Real Connections</title>
        <meta name="description" content="Join Two 2 Tango's exclusive membership for meaningful connections built on shared values. Experience personalized matchmaking and enhanced social experiences." />
      </Helmet>
      <AlertMessage
        variant={alertData.variant}
        message={alertData.message}
        show={alertData.showAlert}
        delay={3000}
        date={alertData.date}
      />
      <section className="my-membership-section">
        <Container>
          <Row>
            <Col>
              <div className="my-membership-section-inner">
                <h2
                  className="healine"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                >
                  <span>My Membership</span>
                </h2>
                <Form>
                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="MembershipType"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <Form.Label column sm="4">
                      Membership Type:
                    </Form.Label>
                    <Col sm="8">
                      {/* <Form.Control
                        type="text"
                        placeholder="Membership Type"
                        value={membershipInfo.packageName}
                      /> */}
                      <Form.Control
                        as="textarea"
                        placeholder="Membership Type"
                        value={membershipInfo.packageName}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="MembershipStartDate"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <Form.Label column sm="4">
                      Membership Start Date:
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Membership Start Date"
                        value={membershipInfo.membershipStartDate.split("T")[0]}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="MembershipEndtDate"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <Form.Label column sm="4">
                      Membership End Date:
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Membership End Date"
                        value={membershipInfo.membershipEndDate.split("T")[0]}
                      />
                    </Col>
                  </Form.Group>
                  <div
                    className="membership-action-btns"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    {membershipInfo.membershipDuration === "1" ? (
                      <>
                        <Button
                          onClick={() => handleCancelSubs("cancel")}
                          disabled={disableBtn.cancel}
                        >
                          Cancel Subscription
                        </Button>
                        <Button
                          onClick={() =>
                            handleUpdateSubs(
                              "update",
                              userPkgId.split(",").length
                            )
                          }
                          disabled={disableBtn.update}
                        >
                          {userPkgId.split(",").length == 1
                            ? "Add on Cupid's Concierge"
                            : "Remove Cupid's Concierge"}
                        </Button>
                        {membershipInfo.membershipDuration !==
                        maxDuration.toString() ? (
                          <Button
                            onClick={() => handleUpgrade("upgrade")}
                            disabled={disableBtn.onChange}
                          >
                            Change of Plan
                          </Button>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => handleUpgrade("upgrade")}
                          disabled={
                            (disableBtn.upgrade ||
                              membershipInfo.membershipDaysLeft > 16) &&
                            membershipInfo.membershipDuration !== "3"
                          }
                        >
                          Change of Plan
                        </Button>
                        {userPkgId.split(",").length !== 2 &&
                        membershipInfo.membershipDuration !== "3" ? (
                          <Button
                            onClick={() =>
                              handleUpgrade(
                                "addon",
                                userPkgId.split(",").length
                              )
                            }
                            disabled={disableBtn.addon}
                          >
                            Add on Cupid's Concierge
                          </Button>
                        ) : (
                          ""
                        )}
                        {membershipInfo.membershipDuration !== "3" ? (
                          <Button
                            onClick={() => handleRenew("renew")}
                            disabled={
                              disableBtn.renew ||
                              membershipInfo.membershipDaysLeft > 16
                            }
                          >
                            RENEW
                          </Button>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <Modal
        className="upgrade-membership-modal"
        show={show}
        onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Title>Upgrade Membership</Modal.Title>
        <Modal.Body>
          Thank you for your interest in renewing your membership. Your
          relationship manager will get in touch with you soon regarding the
          next steps.
        </Modal.Body>
      </Modal> */}
    </>
  );
}
