import React from "react";
import "./ThankYou.css";
import { Col, Container, Row } from "react-bootstrap";
import TTTlogo from "../../images/two2tangoo-logo2.png";
import { useSearchParams } from "react-router-dom";

export default function ThankYou() {
  const [searchParam] = useSearchParams();
  const msg = searchParam.get("msg");

  return (
    <>
      <section className="thankyou-page">
        <Container>
          <Row>
            <Col
              lg={12}
              md={12}
              className="thankyou-page-col"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <div className="thankyou-page-col-inner">
                <div className="site-logo">
                  <img src={TTTlogo} alt="TTT Logo" />
                </div>
                <div className="thankyou-info">
                  {!msg ? (
                    <p>
                      Thank you for joining our waitlist.
                      <br />
                      We will be in touch as soon as we start accepting new
                      clients.
                    </p>
                  ) : (
                    <p>Thank you for your interest in joining the Two2Tango community.<br/> 
                    A member of our team will get in touch with you soon.</p>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
