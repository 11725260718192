import { Button, Form } from "react-bootstrap";
import "./MyProfile.css";
import { useEffect, useState } from "react";
import { GetUserQnA, SaveQnA } from "../../apis/ProfileAPI";
import useInterval from "use-interval";
import { RenderRadio } from "../../Components/FormInputs/RenderRadio";
import { RenderRadioText } from "../../Components/FormInputs/RenderRadioText";
import { RenderTextarea } from "../../Components/FormInputs/RenderTextarea";
import { RenderCheckbox } from "../../Components/FormInputs/RenderCheckbox";

var changeDetectedPart = false;

export default function Partner(prop) {
  const [partnerData, setPartnerData] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [autoSaveDelay] = useState(2 * 60 * 1000);

  const defaultButtonText = {
    save: "Save",
    submit: "Submit",
  };
  const [buttonText, setButtonText] = useState(defaultButtonText);

  useEffect(() => {
    const GetData = async () => {
      var result = await GetUserQnA("partner", prop.userid ?? 0);
      const updatedQuestions = result.map((element) => ({
        ...element,
        answer: {
          value: element.answer,
          isInvalid:
            element.answer === "" ||
            element.answer === null ||
            element.answer === "null",
          mode: "save",
        },
      }));
      setPartnerData(updatedQuestions);
    };

    GetData();
    setButtonText(defaultButtonText);
  }, []);

  const start = 18;
  const end = 70;

  // Create an array with numbers from start to end
  const ageArray = Array.from(
    { length: end - start + 1 },
    (_, index) => start + index
  );

  const updateQnA = (qn, ans, mode = "save") => {
    if (prop.autoSaveMode.partner) changeDetectedPart = true;
    setPartnerData((prev) => {
      return prev.map((q) =>
        q.question_id === qn.question_id
          ? {
              ...q,
              answer: {
                ...q.answer,
                value: ans,
                isInvalid: ans === "" || ans === null,
                mode: mode,
              },
            }
          : q
      );
    });
  };

  const updateMode = (mode) => {
    const qnaData = [...partnerData];
    // Update mode for userQnA
    if (qnaData) {
      if (Array.isArray(qnaData)) {
        // Handle array of questions
        qnaData.forEach((qn) => {
          qn.answer.mode = mode;
        });
      }
    }
    setPartnerData(qnaData);
  };

  const savepartner = async (mode, type) => {
    if (type !== "auto")
      setButtonText((prevState) => ({
        ...prevState,
        [mode]: mode === "save" ? "Saving..." : "Submitting...",
      }));
    var qna = [];
    partnerData.forEach((item) => {
      qna.push({ QuestionId: item.question_id, Answer: item.answer.value });
    });
    const abtMe = {
      userId: 0,
      firstName: "",
      lastName: "",
      phoneNo: "",
      gender: "",
      birthPlace: "",
      address: "",
      city: "",
      qnA: qna,
      mode: mode === "save" ? 0 : 1,
    };
    if (mode === "save") {
      updateMode("save");
      await SaveQnA(abtMe);
      setButtonText((prevState) => ({
        ...prevState,
        [mode]: "Saved",
      }));
      setDisableBtn(false);
      setDefaultButtonText();
    } else if (mode === "submit") {
      updateMode("submit");
      var isValid = isObjectValid(partnerData);
      if (isValid) {
        await SaveQnA(abtMe);
        setButtonText((prevState) => ({
          ...prevState,
          [mode]: "Submitted",
        }));
      } else {
        setButtonText((prevState) => ({
          ...prevState,
          [mode]: "Fill all the fields.",
        }));
        setDefaultButtonText(5000);
      }
      setDisableBtn(false);
      setDefaultButtonText();
    }
  };

  const isObjectValid = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key].answer.isInvalid) {
          return false;
        }
      }
    }
    return true; // All properties have valid values
  };

  const handleAge = (item, v, type) => {
   // console.log(item, v, type);
    var age =
      item.answer.value == null ||
      item.answer.value == "null" ||
      item.answer.value == ""
        ? [0, 0]
        : item.answer.value.split(",");
    if (type === "from") age[0] = v;
    if (type === "to") age[1] = v;

    if (
      age[0] <= age[1] ||
      item.answer.value == null ||
      item.answer.value == "null" ||
      item.answer.value == ""
    ) {
      var ans = `${age[0]},${age[1]}`;
      updateQnA(item, ans);
    }
  };

  const handleCheckboxChange = (event, item) => {
    const value = event.target.value;
    var schk = item.answer.value == null ? [] : item.answer.value.split("~");
    if (schk.includes(value)) {
      schk = schk.filter((option) => option !== value);
    } else {
      schk = [...schk, value];
    }
    updateQnA(item, schk.join("~"));
  };

  useInterval(() => {
    if (prop.autoSaveMode.partner) {
      autoSaveData();
    }
  }, autoSaveDelay);

  function autoSaveData() {
    if (changeDetectedPart) {
      savepartner("save", "auto");
      setButtonText((prevState) => ({ ...prevState, save: "Auto Saving..." }));
    }
  }

  const setDefaultButtonText = (t = 3000) => {
    changeDetectedPart = false;
    setTimeout(() => {
      setButtonText(defaultButtonText);
    }, t);
  };

  return (
    <div className="my-profile-info">
      <Form>
        {partnerData.map((item, indx) =>
          item.field_type === "radio" ? (
            <RenderRadio item={item} updateQnA={updateQnA} />
          ) : item.field_type === "radio_text" ? (
            <RenderRadioText item={item} updateQnA={updateQnA} />
          ) : item.field_type === "textarea" ? (
            <RenderTextarea item={item} updateQnA={updateQnA} />
          ) : item.field_type === "select_2" ? (
            <Form.Group
              className="my-profile-info-form-row"
              controlId={"Q_" + item.question_id}
              data-aos="fade-up"
              data-aos-duration="1000"
              key={"Q_" + item.question_id}
            >
              <Form.Label column key={"QL_" + item.question_id}>
                {item.question}
              </Form.Label>
              <Form.Select
                key={"QS_" + item.question_id}
                aria-label={item.question}
                size="sm"
                value={
                  item.answer.value == null
                    ? ""
                    : `${item.answer.value.split(",")[0]}`
                }
                onChange={(e) => handleAge(item, e.target.value, "from")}
                isInvalid={item.answer.isInvalid && item.answer.mode !== "save"}
              >
                <option key="from">From</option>
                {ageArray.map((f, i) => (
                  <option key={"QS_" + i}>{f}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please fill this field
              </Form.Control.Feedback>
              {"–"}
              <Form.Select
                key={"QS1_" + item.question_id}
                aria-label={item.question}
                size="sm"
                value={
                  item.answer.value == null
                    ? ""
                    : `${item.answer.value.split(",")[1]}`
                }
                onChange={(e) => handleAge(item, e.target.value, "to")}
                isInvalid={item.answer.isInvalid && item.answer.mode !== "save"}
              >
                <option key="to">To</option>
                {ageArray.map((f, i) => (
                  <option key={"QS0_" + i}>{f}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please fill this field
              </Form.Control.Feedback>
            </Form.Group>
          ) : item.field_type === "checkbox" ? (
            <RenderCheckbox
              item={item}
              handleCheckboxChange={handleCheckboxChange}
            />
          ) : (
            ""
          )
        )}
        {!prop.userid && (
          <div className="submit-action-btns">
            <Button
              type="button"
              size="sm"
              data-aos="fade-up"
              data-aos-duration="1000"
              className="join-btn btn btn-primary aos-init aos-animate"
              onClick={() => savepartner("save")}
              disabled={disableBtn}
            >
              {buttonText.save}
            </Button>{" "}
            <Button
              type="button"
              size="sm"
              data-aos="fade-up"
              data-aos-duration="1000"
              className="join-btn btn btn-primary aos-init aos-animate"
              onClick={() => savepartner("submit")}
              disabled={disableBtn}
            >
              {buttonText.submit}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
}
